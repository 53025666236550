import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useEffect } from 'react';

import { Colors } from '../../styles/colors';

const resumeGeneratorImage1 = '/images/tools/resume-1-form.png';
const resumeGeneratorImage2 = '/images/tools/resume-2-ai.png';
const resumeGeneratorImage3 = '/images/tools/resume-3-tailor.png';
const resumeGeneratorImage4 = '/images/tools/resume-4-design.png';
const resumeGeneratorImage5 = '/images/tools/resume-5-customize.png';

import { Pricing } from '../../components/molecules/Pricing';
import { Copy, H2, H3 } from '../../components/atoms/Typography';
import { TwoColumnsIntro } from '../../components/molecules/TwoColumnsIntro';
import { PrimaryButtonLink } from '../../components/atoms/Button/Buttons';
import { Spacer } from '../../components/atoms/Spacer/Spacer';
import { ChevronRightIcon } from '../../components/atoms/Icons';
import { Layout } from '../../components/atoms/Layout/Layout';
import { BackgroundColor } from '../../components/atoms/BackgroundColor';
import {
  ResumePreview,
  ResumesPreviewCentralWrapper,
  ResumesPreviewLeftWrapper,
  ResumesPreviewRightWrapper,
  ResumesPreviewWrapper,
} from '../../components/molecules/ResumePreview';
import { media } from '../../styles/media';
import { FunctionalIFrameComponent } from '../../components/atoms/FunctionalIFrameComponent';

import { stateMozard } from '../../states/stateMozard';
import { stateBeethoven } from '../../states/stateBeethoven';
import { stateChopin } from '../../states/stateChopin';

const ResumeGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet title="AI Resume Generator" />

      <BackgroundColor backgroundColor={Colors.ContrastLightest} hasWave={false}>
        <TableWrapper>
          <Wrapper>
            <ResumesPreviewWrapper>
              <ResumesPreviewLeftWrapper>
                <FunctionalIFrameComponent title="resume">
                  <ResumePreview state={stateBeethoven} isAnimated={false} hasNavigation={false} />
                </FunctionalIFrameComponent>
              </ResumesPreviewLeftWrapper>
              <ResumesPreviewCentralWrapper>
                <FunctionalIFrameComponent title="resume">
                  <ResumePreview state={stateMozard} isAnimated={true} hasNavigation={false} />
                </FunctionalIFrameComponent>
              </ResumesPreviewCentralWrapper>
              <ResumesPreviewRightWrapper>
                <FunctionalIFrameComponent title="resume">
                  <ResumePreview state={stateChopin} isAnimated={false} hasNavigation={false} />
                </FunctionalIFrameComponent>
              </ResumesPreviewRightWrapper>
            </ResumesPreviewWrapper>
          </Wrapper>
          <Wrapper>
            <H2>AI Resume Builder</H2>
            <H3>Craft Your Ultimate Resume with our AI assistant.</H3>
            <Copy>
              Elevate your chances with a standout resume, customized swiftly for each application, opening doors to a
              plethora of career opportunities.
            </Copy>
            <Spacer y={32} />
            <PrimaryButtonLink inline={true} to="/login" iconRight={<ChevronRightIcon color={Colors.White} />}>
              Create your Resume Today
            </PrimaryButtonLink>
          </Wrapper>
        </TableWrapper>
      </BackgroundColor>

      <TwoColumnsIntro image={resumeGeneratorImage1} isInverted={false}>
        <H3>Resume writing made easy</H3>
        <H2>Simplified Form-Filling</H2>
        <Copy>
          Stand out from the crowd and accelerate your job hunt with our proven resume templates. Crafting a winning
          resume is made simple - just fill in the blanks. Take it a step further with AI assistance, which effortlessly
          completes missing details for you.
        </Copy>
        <Spacer y={32} />
        <PrimaryButtonLink inline={true} to="/login" iconRight={<ChevronRightIcon color={Colors.White} />}>
          Sign Up for Free
        </PrimaryButtonLink>
      </TwoColumnsIntro>

      <TwoColumnsIntro image={resumeGeneratorImage2} isInverted={true}>
        <H3>Customize and Enhance with AI </H3>
        <H2>AI-Powered</H2>
        <Copy>
          Elevate your job search strategy with AI-generated resume summaries, accomplishments, and cover letters.
          Streamline the resume crafting process and fast-track your journey to securing your ideal role. Let our
          integrated AI refine and optimize your resume content effortlessly. <b>Premium Members only</b>.
        </Copy>
        <Spacer y={32} />
        <PrimaryButtonLink inline={true} to="/login" iconRight={<ChevronRightIcon color={Colors.White} />}>
          Create your Resume Today
        </PrimaryButtonLink>
      </TwoColumnsIntro>

      <TwoColumnsIntro image={resumeGeneratorImage3} isInverted={false}>
        <H3>Tailored Resumes for Impactful Applications</H3>
        <H2>Job-Specific Tailoring</H2>
        <Copy>
          Jumpstart your application process by swiftly customizing your resume to align with each job opportunity. Our
          AI empowers you to effortlessly tailor resumes and cover letters for each position, in mere seconds, giving
          you a competitive edge in the job market. <b>Premium Members only</b>.
        </Copy>
        <Spacer y={32} />
        <PrimaryButtonLink inline={true} to="/login" iconRight={<ChevronRightIcon color={Colors.White} />}>
          Customise your Resume Today
        </PrimaryButtonLink>
      </TwoColumnsIntro>

      <TwoColumnsIntro image={resumeGeneratorImage4} isInverted={true}>
        <H3>Make it your own</H3>
        <H2>Design Options</H2>
        <Copy>
          Choose from a rich collection of resume, CV, and cover letter templates, catering to both traditional and
          modern preferences. Pick your favorite template and get on your way to your new job.
        </Copy>
        <Spacer y={32} />
        <PrimaryButtonLink inline={true} to="/login" iconRight={<ChevronRightIcon color={Colors.White} />}>
          Customise your Resume Today
        </PrimaryButtonLink>
      </TwoColumnsIntro>

      <TwoColumnsIntro image={resumeGeneratorImage5} isInverted={false}>
        <H3>Highly customizable</H3>
        <H2>Customisation</H2>
        <Copy>
          Showcase your professional identity with our meticulously crafted resume templates, tailored to meet ATS
          standards. Customize your resume with an abundance of options, allowing your unique personality to shine
          through using our intuitive resume maker.
        </Copy>
        <Spacer y={32} />
        <PrimaryButtonLink inline={true} to="/login" iconRight={<ChevronRightIcon color={Colors.White} />}>
          Customise your Resume Today
        </PrimaryButtonLink>
      </TwoColumnsIntro>

      <BackgroundColor backgroundColor={Colors.White} hasWave={false}>
        <Layout>
          <Pricing handlePlanSelect={() => {}} />
        </Layout>
      </BackgroundColor>
    </>
  );
};

export default ResumeGenerator;

const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  ${media.from_tablet`
    grid-template-columns: 2fr 1fr;
    gap: 64px;
    > :nth-of-type(1) {
      order: 1;
    }
    > :nth-of-type(2) {
      order: 2;
    }
    > :nth-of-type(3) {
      order: 3;
    }
    > :nth-of-type(4) {
      order: 4;
    }
    > :nth-of-type(5) {
      order: 6;
    }
    > :nth-of-type(6) {
      order: 5;
    }
    > :nth-of-type(7) {
      order: 7;
    }
    > :nth-of-type(8) {
      order: 8;
    }
  `}
`;

const Wrapper = styled.div``;
